<template>
  <div class="Home" v-if="renderMain">
    <!-- *********inicia intro del home********** -->
    <v-container fluid class="py-0 cursor-custom-logo">
      <v-row>
        <v-col class="pa-0 position-relative column-intro">
          <carousel2
            :items="1"
            :autoplay="true"
            :loop="true"
            :nav="false"
            :dots="false"
            :animateIn="'fadeIn'"
            :animateOut="'fadeOut'"
            :slideTransition="'linear'"
            :mouseDrag="false"
          >
            <template v-for="(asset, as) in homeSingleton.carousel_intro">
              <div v-bind:key="as">
                <template v-if="asset.value.image == true">
                  <img
                    :src="
                      'https://www.ofisdesigncms.actstudio.xyz/storage/uploads' + asset.value.path
                    "
                    style="object-fit: cover"
                  >
                </template>

                <template v-else-if="asset.value.video == true">
                  <video
                    autoplay
                    loop
                    muted
                    width="100%"
                    style="object-fit: cover; height: 100vh !important"
                  >
                    <source
                      type="video/mp4"
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/storage/uploads' +
                        asset.value.path
                      "
                    />
                  </video>
                </template>
              </div>
            </template>
          </carousel2>

          <v-img
            src="@/assets/icons/logo-white-ofis.png"
            class="logo-white-ofis"
          ></v-img>
        </v-col>
      </v-row>

      <!-- **********inicia cuadro amarillo********** -->
      <v-row
        class="Home__yellow-div cursor-custom-logo animation-lazy"
        justify="center"
        align="center"
        @mouseover="showIcon = false"
        @mouseleave="showIcon = true"
      >
        <v-col cols="12">
          <v-container class="px-8 px-sm-12 px-md-3 py-0">
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="12" lg="11">
                <v-row no-gutters align="center">
                  <v-col
                    cols="12"
                    sm="auto"
                    :class="{ 'width-if-not-has': !showIcon }"
                  >
                    <template v-if="showIcon">
                      <v-img
                        src="@/assets/icons/logo-negro.svg"
                        class="Home__yellow-div__logo-black"
                      ></v-img>
                    </template>
                  </v-col>

                  <v-col cols="12" sm="auto" class="padding-left-column">
                    <p class="Home__yellow-div--about titles">
                      {{ homeSingleton.about }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <!-- **********/termina cuadro amarillo********** -->
    </v-container>
    <!-- *********/termina intro del home********** -->

    <v-container
      class="px-8 px-sm-12 px-md-3 py-0 mt-12 pt-xl-12 animation-lazy"
    >
      <!-- *****inicia sección de new projects**** -->
      <v-row justify="center">
        <v-col cols="12" lg="11">
          <div class="title_max-width">
            <span class="extra-big-titles aeonik-bold">{{
              homeSingleton.title_1
            }}</span>
          </div>
        </v-col>

        <v-col cols="12" lg="11" class="mt-6 mt-sm-12 pt-xl-11">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-row
                no-gutters
                class="min-content-div same-height-carousel-big-class rewrite-height"
              >
                <v-col cols="12" class="line-height-18 mb-4">
                  <span class="titles d-block aeonik-bold">{{
                    homeSingleton.new_projects.new_projects_title
                  }}</span>
                  <!-- <p class="subtitles mt-4">
                    {{ homeSingleton.new_projects.new_projects_description }}
                  </p> -->
                </v-col>

                <!-- carousel mini oculto en mobile -->
                <v-col cols="12" class="align-self-end d-none d-sm-block">
                  <v-carousel
                    v-model="carouselProjectsHomeMini"
                    v-bind:show-arrows="false"
                    @change="carouselProjectsHome = carouselProjectsHomeMini"
                    hide-delimiters
                    class="Home__carousel-mini"
                  >
                    <v-carousel-item
                      v-for="(project, prhc) in projects"
                      v-bind:key="prhc"
                      transition="fade-slow-transition"
                      reverse-transition="fade-slow-transition"
                    >
                      <v-card
                        height="500"
                        width="100%"
                        tile
                        elevation="0"
                        class="pa-7 pa-lg-12 line-height-18"
                        v-bind:class="{
                          'yellow-element': project.colorOverlay == '#FFF171',
                        }"
                        v-bind:color="project.colorOverlay"
                        v-bind:to="'/project/' + project.url"
                        v-bind:ripple="false"
                      >
                        <div
                          class="Project--paragraphs titles aeonik-bold capitalize word-spaces color-black"
                          v-html="project.name"
                          v-bind:class="{
                            'white--text':
                              project.colorOverlay == '#223151' ||
                              project.colorOverlay == '#1F6D5E',
                            'black--text':
                              project.colorOverlay != '#223151' ||
                              project.colorOverlay != '#1F6D5E',
                          }"
                        ></div>
                        <div>
                          <span
                            class="capitalize subtitles aeonik-light"
                            v-bind:class="{
                              'white--text':
                                project.colorOverlay == '#223151' ||
                                project.colorOverlay == '#1F6D5E',
                              'black--text':
                                project.colorOverlay != '#223151' ||
                                project.colorOverlay != '#1F6D5E',
                            }"
                            >{{ project.location }}</span
                          >
                        </div>
                        <template v-if="project.meters > 0">
                          <div>
                            <span
                              class="capitalize subtitles aeonik-light"
                              v-bind:class="{
                                'white--text':
                                  project.colorOverlay == '#223151' ||
                                  project.colorOverlay == '#1F6D5E',
                                'black--text':
                                  project.colorOverlay != '#223151' ||
                                  project.colorOverlay != '#1F6D5E',
                              }"
                              >{{ numberWithCommas(project.meters) }}m<sup
                                >2</sup
                              ></span
                            >
                          </div>
                        </template>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
                <!-- /carousel mini oculto en mobile -->
              </v-row>
            </v-col>

            <!-- carousel big tablet para arriba -->
            <v-col
              cols="auto"
              class="position-relative Home__col-2-new-projects d-none d-sm-block"
            >
              <v-carousel
                v-model="carouselProjectsHome"
                v-bind:show-arrows="false"
                @change="carouselProjectsHomeMini = carouselProjectsHome"
                class="Home__carousel-big"
                hide-delimiters
              >
                <v-carousel-item
                  v-for="(project, prh) in projects"
                  v-bind:key="prh"
                  transition="fade-slow-transition"
                  reverse-transition="fade-slow-transition"
                >
                  <template v-if="project.cover != null">
                    <v-card
                      :to="'/project/' + project.url"
                      :ripple="false"
                      tile
                      elevation="0"
                      class=""
                      color="white"
                    >
                      <img
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          project.cover.path
                        "
                      >
                      <!-- <span style="z-index: 5" class="black--text">{{
                        project.name
                      }}</span> -->
                    </v-card>
                  </template>
                </v-carousel-item>
              </v-carousel>

              <div class="white-space cursor-arrow" @click="changeSlides"></div>
            </v-col>
            <!-- /carousel big tablet para arriba -->

            <!-- carousel mobile -->
            <v-col
              cols="auto"
              class="position-relative Home__col-2-new-projects Home__carousel-big d-sm-none"
            >
              <v-carousel
                v-model="carouselProjectsHome"
                v-bind:show-arrows="false"
                @change="carouselProjectsHomeMini = carouselProjectsHome"
                class="Home__carousel-big"
                hide-delimiters
              >
                <v-carousel-item
                  v-for="(project, prh) in projects"
                  v-bind:key="prh"
                  transition="fade-slow-transition"
                  reverse-transition="fade-slow-transition"
                >
                  <template v-if="project.miniature != null">
                    <v-card
                      :to="'/project/' + project.url"
                      :ripple="false"
                      tile
                      elevation="0"
                      class=""
                      color="white"
                    >
                      <v-img
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          project.miniature.path
                        "
                      ></v-img>
                      <!-- <span style="z-index: 5" class="black--text">{{
                        project.name
                      }}</span> -->
                    </v-card>
                  </template>
                </v-carousel-item>
              </v-carousel>

              <div class="white-space cursor-arrow" @click="changeSlides"></div>
            </v-col>
            <!-- /carousel mobile -->

            <v-col class="d-sm-none">
              <v-card
                v-for="(project, pr5) in projects"
                v-bind:key="pr5"
                v-show="pr5 == carouselProjectsHome"
                v-bind:to="'/project/' + project.url"
                v-bind:ripple="false"
                tile
                elevation="0"
                class="toClick"
              >
                <v-card-text class="Project--line-height-texts pt-3 pb-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <div
                        v-html="project.name"
                        class="capitalize aeonik-bold Projects__paragraphs rewrite-fontsize color-black"
                      ></div>
                      <span
                        class="color-black subtitles-card-projects capitalize d-block rewrite-fontsize"
                      >
                        {{ project.location }}
                      </span>
                      <template v-if="project.meters > 0">
                        <span
                          class="color-black subtitles-card-projects rewrite-fontsize"
                        >
                          {{ numberWithCommas(project.meters) }} m<sup>2</sup>
                        </span>
                      </template>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="margin-top-custom">
            <v-col cols="auto" class="max-width-col"></v-col>
            <v-col
              cols="auto"
              class="position-relative Home__col-2-new-projects-2"
            >
              <p class="max-width-p subtitles line-height-18">
                {{ homeSingleton.ofis_mission }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- *****/termina sección de new projects**** -->
    </v-container>

    <v-container fluid class="py-0 animation-lazy">
      <!-- *****inicia carousel de miniaturas del proyecto**** -->
      <v-row>
        <v-col cols="12" class="pa-0">
          <template v-if="render">
            <carousel
              :nav="false"
              :autoplay="true"
              :margin="50"
              :loop="true"
              :dots="false"
              :autoplayHoverPause="true"
              :autoplayTimeout="3000"
              :autoplaySpeed="3000"
              :smartSpeed="1500"
              :center="true"
              :autoWidth="true"
              :fluidSpeed="10000"
              :rewind="false"
              slideTransition="linear"
            >
              <v-card
                v-for="(project, prc) in featuredProjects"
                v-bind:key="prc"
                v-bind:to="'/project/' + project.url"
                class="Home__carousel-card"
                tile
                elevation="0"
              >
                <div class="activate-overlay">
                  <div>
                    <template v-if="project.miniature != null">
                      <img
                        v-bind:src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          project.miniature.path
                        "
                        height="100%"
                        width="100%"
                        class="Home__carousel-card--image"
                      />
                    </template>
                  </div>
                  <!-- overlay -->
                  <div
                    class="overlay toClick"
                    v-bind:style="'background-color: ' + project.colorOverlay"
                    v-bind:class="{
                      'yellow-element': project.colorOverlay == '#FFF171',
                    }"
                  >
                    <div
                      class="Project--paragraphs color-black titles aeonik-bold capitalize word-spaces"
                      v-html="project.name"
                      v-bind:class="{
                        'white--text':
                          project.colorOverlay == '#223151' ||
                          project.colorOverlay == '#1F6D5E',
                        'black--text':
                          project.colorOverlay != '#223151' ||
                          project.colorOverlay != '#1F6D5E',
                      }"
                    ></div>
                    <div>
                      <span
                        class="capitalize subtitles aeonik-light"
                        v-bind:class="{
                          'white--text':
                            project.colorOverlay == '#223151' ||
                            project.colorOverlay == '#1F6D5E',
                          'black--text':
                            project.colorOverlay != '#223151' ||
                            project.colorOverlay != '#1F6D5E',
                        }"
                        >{{ project.location }}</span
                      >
                    </div>
                    <template v-if="project.meters > 0">
                      <div>
                        <span
                          class="capitalize subtitles aeonik-light"
                          v-bind:class="{
                            'white--text':
                              project.colorOverlay == '#223151' ||
                              project.colorOverlay == '#1F6D5E',
                            'black--text':
                              project.colorOverlay != '#223151' ||
                              project.colorOverlay != '#1F6D5E',
                          }"
                          >{{ numberWithCommas(project.meters) }}m<sup
                            >2</sup
                          ></span
                        >
                      </div>
                    </template>
                  </div>
                  <!-- /overlay -->
                </div>
              </v-card>
            </carousel>
          </template>
        </v-col>
      </v-row>
      <!-- *****/termina carousel de miniaturas del proyecto**** -->
    </v-container>

    <v-container class="px-8 px-sm-12 px-md-3 py-0">
      <!-- *****inicia fragmento de sección designers ***OCULTO**** -->
      <v-row
        v-if="false"
        justify-sm="center"
        class="margin-top-custom cursor-custom-logo animation-lazy"
      >
        <v-col cols="auto">
          <div class="title_max-width">
            <span class="extra-big-titles aeonik-bold">{{
              homeSingleton.title_3
            }}</span>
          </div>
        </v-col>

        <v-col cols="12" class="pt-6 pt-sm-12">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 4">
                      <div class="bar-container" :key="im + '-5'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                          class="Designers__container-content__row--square-image"
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 5">
                      <div class="bar-container" :key="im + '-6'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                          class="Designers__container-content__row--square-image"
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
                <v-col cols="12">
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 6">
                      <div class="bar-container" :key="im + '-7'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="6">
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 7">
                      <div class="bar-container" :key="im + '-8'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
                <v-col cols="6">
                  <v-row no-gutters>
                    <v-col>
                      <template v-for="(asset, im) in designersA">
                        <template v-if="asset.image != null && im == 8">
                          <div class="bar-container" :key="im + '-9'">
                            <v-img
                              :src="
                                'https://www.ofisdesigncms.actstudio.xyz/' +
                                asset.image.path
                              "
                              class="Designers__container-content__row--square-image"
                            ></v-img>
                            <div
                              class="bar-content"
                              v-bind:style="
                                'background-color: ' + asset.barColor + ';'
                              "
                              v-bind:class="{
                                'white--text':
                                  asset.barColor == '#223151' ||
                                  asset.barColor == '#1F6D5E' ||
                                  asset.barColor == '#B2B2B2',
                              }"
                            >
                              <v-row
                                align="center"
                                class="fill-height"
                                no-gutters
                              >
                                <v-col class="text-center">
                                  <span>
                                    {{ asset.description }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </template>
                      </template>
                    </v-col>
                    <v-col>
                      <template v-for="(asset, im) in designersA">
                        <template v-if="asset.image != null && im == 9">
                          <div class="bar-container" :key="im + '-10'">
                            <v-img
                              :src="
                                'https://www.ofisdesigncms.actstudio.xyz/' +
                                asset.image.path
                              "
                              class="Designers__container-content__row--square-image"
                            ></v-img>
                            <div
                              class="bar-content"
                              v-bind:style="
                                'background-color: ' + asset.barColor + ';'
                              "
                              v-bind:class="{
                                'white--text':
                                  asset.barColor == '#223151' ||
                                  asset.barColor == '#1F6D5E' ||
                                  asset.barColor == '#B2B2B2',
                              }"
                            >
                              <v-row
                                align="center"
                                class="fill-height"
                                no-gutters
                              >
                                <v-col class="text-center">
                                  <span>
                                    {{ asset.description }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </template>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- *****/termina fragmento de sección designers ***OCULTO**** -->

      <!-- *****inicia proceso**** -->
      <v-row class="margin-top-custom animation-lazy">
        <v-col cols="12" class="pl-sm-12 ml-md-12 line-height-18 pb-0">
          <span class="titles d-block aeonik-bold">{{
              homeSingleton.process_content.title_process
            }}</span>
        </v-col>
        <v-col cols="12" class="line-height-18 pt-0">
         <template v-if="$vuetify.breakpoint.smAndUp">
            <img class="img-process" :src="'https://www.ofisdesigncms.actstudio.xyz/'+homeSingleton.process_content.image_process.path" alt=""/>
         </template>

         <template v-if="$vuetify.breakpoint.xs">
            <img class="img-process" :src="'https://www.ofisdesigncms.actstudio.xyz/'+homeSingleton.process_content.image_process_mobile.path" alt=""/>
         </template>
          <!-- <div class="process-description">
            <p class="subtitles mt-4">
              {{ homeSingleton.process_content.subtitle_process }}
            </p>
            <p class="subtitles mt-4 mb-0 mb-sm-4" style="white-space: pre-line">
              {{ homeSingleton.process_content.description_process }}
            </p>
          </div> -->
        </v-col>

        <!-- <v-col cols="12" sm="5" class=" line-height-18">
          <div class="process-description-2">
            <p class="subtitles" style="white-space: pre-line">
              {{ homeSingleton.process_content.description_process_2 }}
            </p>
          </div>
        </v-col> -->

        <v-col cols="12" class="position-relative comparison">
          <VueCompareImage
            hover
            :handleSize="0"
            :leftImage="
              'https://www.ofisdesigncms.actstudio.xyz' +
              homeSingleton.process_image_before.path
            "
            :rightImage="
              'https://www.ofisdesigncms.actstudio.xyz' +
              homeSingleton.process_image_after.path
            "
          />
        </v-col>
      </v-row>
      <!-- *****/termina proceso**** -->

      <v-row class="margin-top-custom animation-lazy">
        <v-col cols="auto" class="pl-sm-12 ml-md-12">
          <p class="titles text-services mt-4 line-height-18 aeonik-bold">
            {{ homeSingleton.text_services }}
          </p>
        </v-col>
      </v-row>
    </v-container>

    <!-- *****inicia servicios**** -->
    <v-container fluid class="py-0 cursor-custom-logo animation-lazy">
      <v-row>
        <!-- ************col-1************ -->
        <v-col
          cols="12"
          sm="6"
          class="background-green service-col columns-services pa-0"
          v-bind:class="{
            'cut-background-green':
              serviceHover == 1 || serviceHover == 2 || serviceHover == 3,
          }"
          @mouseout="serviceHover = null"
          @mouseover="serviceHover = 0"
        >
          <div
            class="text-container"
            v-bind:class="{
              'd-none':
                serviceHover == 1 || serviceHover == 2 || serviceHover == 3,
            }"
          >
            <span class="big-titles aeonik-bold">
              {{ homeSingleton.service_1_title }}
            </span>
            <p class="titles rewrite-in-ipad-h line-height-18">
              {{ homeSingleton.service_1_description }}
            </p>
          </div>
          <div class="container-images-services">
            <div class="rounded-blue"></div>
            <div class="rounded-transparent"></div>

            <!-- if == 1 -->
            <img
              v-show="serviceHover == 1"
              width="100%"
              height="100%"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_2_image1.path
              "
              class="image-service"
            />
            <!-- /if == 1 -->
            <!-- if == 2 -->
            <img
              v-show="serviceHover == 2"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_3_image1.path
              "
              class="image-service"
            />
            <!-- /if == 2 -->

            <!-- if == 3 -->
            <img
              v-show="serviceHover == 3"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_4_image2.path
              "
              class="image-service"
            />
            <!--  -->
            <!-- /if == 3 -->
          </div>
        </v-col>
        <!-- ************/col-1************ -->

        <!-- ************col-2************ -->
        <v-col
          cols="12"
          sm="6"
          class="background-yellow yellow-element service-col columns-services pa-0"
          v-bind:class="{
            'not-selected': serviceHover != null && serviceHover != 1,
          }"
          @mouseover="serviceHover = 1"
          @mouseout="serviceHover = null"
        >
          <div
            class="text-container"
            v-bind:class="{
              'd-none':
                serviceHover == 0 || serviceHover == 2 || serviceHover == 3,
            }"
          >
            <span class="big-titles aeonik-bold">
              {{ homeSingleton.service_2_title }}
            </span>
            <p class="titles rewrite-in-ipad-h line-height-18">
              {{ homeSingleton.service_2_description }}
            </p>
          </div>

          <div class="container-images-services">
            <img
              v-show="serviceHover == 0"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_1_image1.path
              "
              v-bind:class="{ circle_center: serviceHover == 0 }"
              class="image-service"
            />

            <!-- if == 2 -->
            <img
              v-show="serviceHover == 2"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_3_image2.path
              "
              v-bind:class="{ circle_center: serviceHover == 2 }"
              class="image-service"
            />
            <!-- /if == 2 -->

            <!-- if == 3 -->
            <img
              v-show="serviceHover == 3"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_4_image1.path
              "
              v-bind:class="{ circle_center: serviceHover == 3 }"
              class="image-service"
            />
            <!-- /if == 3 -->
          </div>
        </v-col>
        <!-- ************/col-2************ -->

        <!-- ************col-3************ -->
        <v-col
          cols="12"
          sm="6"
          class="background-light-green service-col columns-services pa-0"
          v-bind:class="{
            'not-selected': serviceHover != null && serviceHover != 2,
            'pa-6': serviceHover == 0 || serviceHover == 1 || serviceHover == 3,
          }"
          @mouseover="serviceHover = 2"
          @mouseout="serviceHover = null"
        >
          <div
            class="text-container"
            v-bind:class="{
              'd-none':
                serviceHover == 0 || serviceHover == 1 || serviceHover == 3,
            }"
          >
            <span class="big-titles aeonik-bold">
              {{ homeSingleton.service_3_title }}
            </span>
            <p class="titles rewrite-in-ipad-h line-height-18">
              {{ homeSingleton.service_3_description }}
            </p>
          </div>
          <div
            class="container-images-services"
            style="height: -webkit-fill-available !important"
          >
            <!-- if == 0 -->
            <img
              v-show="serviceHover == 0"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_1_image2.path
              "
              class="image-service"
            />
            <!-- /if == 0 -->

            <!-- if == 1 -->
            <img
              v-show="serviceHover == 1"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_2_image2.path
              "
              class="image-service"
            />
            <!-- /if == 1 -->

            <!-- if == 3 -->
            <img
              v-show="serviceHover == 3"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_4_image3.path
              "
              class="image-service"
            />
            <!-- /if == 3 -->
          </div>
        </v-col>
        <!-- ************/col-3************ -->

        <!-- ************col-4************ -->
        <v-col
          cols="12"
          sm="6"
          class="background-blue service-col columns-services pa-0"
          v-bind:class="{
            'not-selected': serviceHover != null && serviceHover != 3,
            'pa-6': serviceHover == 2,
            'cut-background-blue': serviceHover == 0,
          }"
          @mouseout="serviceHover = null"
          @mouseover="serviceHover = 3"
        >
          <div
            class="text-container"
            v-bind:class="{
              'd-none':
                serviceHover == 0 || serviceHover == 1 || serviceHover == 2,
            }"
          >
            <span class="big-titles aeonik-bold">
              {{ homeSingleton.service_4_title }}
            </span>
            <p class="titles rewrite-in-ipad-h line-height-18">
              {{ homeSingleton.service_4_description }}
            </p>
          </div>
          <div class="container-images-services"  style="height: -webkit-fill-available !important">
            <!-- if == 0 -->
            <img
              v-show="serviceHover == 0"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_1_image3.path
              "
              class="image-service"
            />
            <!-- /if == 0 -->

            <!-- if == 1 -->
            <img
              v-show="serviceHover == 1"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_2_image3.path
              "
              v-bind:class="{ circle_center: serviceHover == 1 }"
              class="image-service"
            />
            <!-- /if == 1 -->

            <!-- if == 2 -->
            <img
              v-show="serviceHover == 2"
              v-bind:src="
                'https://www.ofisdesigncms.actstudio.xyz' +
                homeSingleton.service_3_image3.path
              "
              height="100%"
              width="100%"
              class="image-service"
            />
            <!-- /if == 2 -->
          </div>
        </v-col>
        <!-- ************/col-4************ -->
      </v-row>
    </v-container>
    <!-- *****/termina servicios**** -->

    <v-container
      class="margin-top-custom px-8 px-sm-12 px-md-3 py-0 animation-lazy"
    >
      <v-row>
        <v-col cols="auto" class="line-height-18">
          <span class="titles d-block aeonik-bold">{{
            homeSingleton.posts_content.posts_title
          }}</span>
        </v-col>
      </v-row>
    </v-container>
    <News
      :allowLimit="allowLimit"
      :limit="limit"
      :changeContent="1"
      class="mb-xl-12 animation-lazy"
    ></News>
  </div>
</template>

<script>
import Vue from "vue";
import carousel from "vue-owl-carousel";
import carousel2 from "vue-owl-carousel2";
import VueCompareImage from "vue-compare-image";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Video from "@splidejs/splide-extension-video";
import News from "../components/Globals/NewsLimit";
import "animate.css";

export default {
  name: "Home",
  components: {
    carousel,
    carousel2,
    VueCompareImage,
    Splide,
    SplideSlide,
    News,
  },
  computed: {
    featuredProjects: function () {
      var projects = this.projects;
      var featuredProjects = [];

      projects.forEach((pro) => {
        if (pro.featured) {
          featuredProjects.push(pro);
        }
      });

      return featuredProjects;
    },
  },
  data() {
    return {
      renderMain: false,
      serviceHover: null,
      render: false,
      homeSingleton: {},
      projects: [],
      mouseMoveVar: false,
      //mini carousel
      carouselProjectsHomeMini: 0,
      heightCarouselProjectsHomeMini: 425,
      //big carousel
      carouselProjectsHome: 0,
      heightCarouselProjectsHome: 800,
      //para sección de designers
      designersA: [],
      //prosp para componente de news
      limit: 2,
      allowLimit: true,
      //splide
      //splide
      optionsSliderIntro: {
        type: "fade",
        width: "100vw",
        height: "100vh",
        arrows: false,
        autoplay: true,
        rewind: true,
        pagination: false,
        speed: 1000,
        rewindSpeed: 3000,
        interval: 10000,
        pauseOnHover: false,
      },
      showIcon: true,
    };
  },
  mounted() {
    //fetch for home´s singleton
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/singletons/get/home?token=e96ec97538f5b51ad80b44386e6d1c"
    )
      .then((data) => data.json())
      .then((data) => (this.homeSingleton = data))
      .then((data) => (this.renderMain = true))
      .catch((error) => (console.log(error.message)));

    //fetch for projects
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
          sort: { _created: -1 },
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.projects = res.entries))
      .then((res) => (this.render = true))
      .catch((error) => console.log(error.message));

    //fetch to designer´s collection
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/Designers?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          limit: 30,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.designersA = res.entries))
      .catch((error) => console.log(error.message));

    this.$emit("update", 0);

    //evento para mobile
    // window.addEventListener("touchmove", this.touchMove);

    //evento para desk
    // window.addEventListener("mousemove", this.slideMove);

    //evento para el resize
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onResize: function (e) {
      //para el carousel del carrusel grande
      // if (window.innerWidth <= 599) {
      //   this.heightCarouselProjectsHome = 345;
      // } else if (window.innerWidth >= 600 && window.innerWidth <= 959) {
      //   this.heightCarouselProjectsHome = 400;
      // } else if (window.innerWidth >= 960 && window.innerWidth <= 1263) {
      //   this.heightCarouselProjectsHome = 571;
      // } else if (window.innerWidth >= 1264 && window.innerWidth <= 1903) {
      //   this.heightCarouselProjectsHome = 600;
      // } else if (window.innerWidth >= 1904) {
      //   this.heightCarouselProjectsHome = 800;
      // }
    },
    changeSlides: function () {
      this.carouselProjectsHomeMini++;

      setTimeout(() => {
        this.carouselProjectsHome = this.carouselProjectsHomeMini;
      }, 500);
    },
    // touchMove: function (e) {
    //   //variable para el layer en comparison
    //   var slide = document.getElementById("layer");

    //   if (slide) {
    //     if (this.mouseMoveVar) {
    //       let x = e.touches[0].clientX;
    //       slide.style.left = x - 10 + "px";
    //     }
    //   }
    // },
  },
};
</script>
