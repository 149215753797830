import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import( /* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/projects/:filter?',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/project/:project',
    name: 'Project',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/designers',
    name: 'Designers',
    component: () => import('../views/Designers.vue')
  },
  {
    path: '/studio',
    name: 'Studio',
    component: () => import('../views/Studio.vue')
  },
  {
    path: '/news/:filter?',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/new/:newPost',
    name: 'New',
    component: () => import('../views/New.vue')
  },
  {
    path: '/awards',
    name: 'Awards',
    component: () => import('../views/Awards.vue')
  },
  {
    path: '/contact',
    name: "Contact",
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/login-admin',
    name: 'Login',
    component: () => import('../views/admin/Login.vue')
  },
  {
    path: '/projectsClients',
    name: 'ProjectsClients',
    component: () => import('../views/admin/ProjectsClients.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

export default router