<template>
  <div class="Footer">
    <!-- modal de términos y condiciones y aviso de privacidad -->
    <TAModal
      :visible="ta_modal"
      :changeContent="ta_number"
      @close="ta_modal = false"
    ></TAModal>
    <!-- /modal de términos y condiciones y aviso de privacidad -->

    <v-footer class="footer-custom yellow-element">
      <v-container class="py-0">
        <v-row justify="space-between">
          <v-col cols="12" sm="auto" class="pb-0 pb-sm-3">
            <v-img
              src="@/assets/icons/logo-ofis-footer.svg"
              class="footer-custom--logo-ofis"
            />
          </v-col>
          <v-col cols="12" sm="auto">
            <v-row no-gutters class="fill-height" align="center">
              <v-col
                cols="12"
                sm="auto"
                class="text-center text-sm-left mt-4 mb-8 pr-sm-6"
              >
                <span class="aeonik-regular x-small-texts">Follow us at</span>
              </v-col>
              <v-col cols="12" sm="auto" class="text-center text-sm-left">
                <template v-for="(socialNetwork, sn) in itemsSocialNetworks">
                  <a
                    v-bind:href="socialNetwork.link"
                    v-bind:key="sn"
                    v-bind:class="{ 'pr-0': sn == 3 }"
                    target="_blank"
                    class="d-inline-block footer-custom--social-networks"
                  >
                    <v-img
                      v-bind:src="socialNetwork.icon"
                      v-bind:class="{
                        'facebook-icon': sn == 0,
                        'instagram-icon': sn == 1,
                        'pinterest-icon d-none': sn == 2,
                        'linkedin-icon d-none': sn == 3,
                      }"
                    ></v-img>
                  </a>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- inician items para navegar desk  -->
        <v-row class="d-none d-sm-block">
          <v-col class="py-0">
            <v-row>
              <v-col cols="auto" class="footer-custom--line-height-1">
                <router-link
                  to="/studio"
                  class="not-text-decoration d-block footer-custom--items-links aeonik-light"
                >
                  <span class="texts capitalize">Studio</span>
                </router-link>

                <router-link
                  to="/projects"
                  class="not-text-decoration d-block footer-custom--items-links aeonik-light"
                >
                  <span class="texts capitalize">Projects</span>
                </router-link>
              </v-col>
              <v-col cols="auto" class="footer-custom--line-height-1">
                <router-link
                  to="/news"
                  class="not-text-decoration d-block footer-custom--items-links aeonik-light"
                >
                  <span class="texts capitalize">News</span>
                </router-link>

                <router-link
                  to="/contact"
                  class="not-text-decoration d-block footer-custom--items-links aeonik-light"
                >
                  <span class="texts capitalize">Contact</span>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- /terminan items para navegar desk -->

        <v-row>
          <v-col class="text-center text-sm-left">
            <!-- OCULTOS TÉRMINOS Y AVISO DE PRIVACIDAD -->
            <div v-if="false">
              <span
                class="aeonik-regular x-small-texts"
                @click="(ta_modal = true), (ta_number = 0)"
                >Términos y condiciones</span
              >
              <span class="aeonik-regular px-3">|</span>
              <span
                class="aeonik-regular x-small-texts"
                @click="(ta_modal = true), (ta_number = 1)"
                >Aviso de privacidad</span
              >
              <!-- <span class="aeonik-regular px-3 d-none d-sm-inline">|</span>
              <br class="d-sm-none" /> -->
              <!-- <span class="aeonik-regular x-small-texts"
                >Política de privacidad</span
              > -->
            </div>
            <!-- /OCULTOS TÉRMINOS Y AVISO DE PRIVACIDAD -->

            <div>
              <span class="aeonik-regular x-small-texts"
                >&copy; Ofisdesign 2020. Todos los derechos reservados.</span
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import facebook from "@/assets/icons/facebook.svg";
import instagram from "@/assets/icons/instagram.svg";
import pinterest from "@/assets/icons/pinterest.svg";
import linkedin from "@/assets/icons/linkedin.svg";
import TAModal from "../Globals/TAModal";

export default {
  name: "Footer",
  components: {
    TAModal,
  },
  data() {
    return {
      //social networks
      itemsSocialNetworks: [
        {
          link: "https://www.facebook.com/ofisdesign",
          icon: facebook,
        },
        {
          link: "https://www.instagram.com/ofis_design/",
          icon: instagram,
        },
        // {
        //   link: "",
        //   icon: pinterest,
        // },
        // {
        //   link: "",
        //   icon: linkedin,
        // },
      ],
      // modal de terminos y condiciones y aviso de privacidad
      ta_modal: false,
      ta_number: 0,
    };
  },
};
</script>