<template>
  <v-expand-transition>
    <v-container v-if="show" class="menu_mobile d-md-none" fluid>
      <v-row v-bind:class="{'opacity-0': show == false, 'animation': show}">
        <v-col cols="12" sm="4" class="same-padding-appbar">
          <template v-for="(itemH, ith) in itemsNav">
            <router-link
              class="not-text-decoration black--text header-app--items-links d-block"
              v-bind:to="itemH.url"
              v-bind:key="ith"
            >
              <span class="texts capitalize aeonik-regular">{{
                itemH.text
              }}</span>
            </router-link>
          </template>
        </v-col>

        <v-col cols="12" sm="8" class="pt-1 same-padding-appbar">
          <span class="d-block mb-2">Keep checking our projects</span>

          <v-row no-gutters>
            <v-col cols="9" sm="7" class="pb-0 position-relative">
              <router-link to="/projects">
                <v-img class="arrow-right" src="@/assets/icons/derecha.svg"></v-img>
              </router-link>

              <v-card
                tile
                elevation="0"
                class="toClick SubMenu__card"
                :to="'/project/' + project.url"
                :ripple="false"
              >
                <template v-if="project.miniature != null">
                  <v-img
                    lazy-src
                    class="Projects--image-view-a"
                    :src="
                      'https://www.ofisdesigncms.actstudio.xyz' +
                      project.miniature.path
                    "
                  ></v-img>
                </template>

                <v-card-text class="Project--line-height-texts px-0 py-1">
                  <v-row>
                    <v-col>
                      <div
                        v-html="project.name"
                        class="capitalize Projects__paragraphs rewrite-fontsize titles-card-projects aeonik-bold color-black"
                      ></div>
                      <span
                        class="color-black subtitles-card-projects capitalize d-block"
                      >
                        {{ project.location }}
                      </span>
                      <span class="color-black subtitles-card-projects">
                        {{ numberWithCommas(project.meters) }}m<sup>2</sup>
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-expand-transition>
</template>

<script>
export default {
  name: "MenuMobile",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    itemsNav: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      project: {},
    };
  },
  beforeCreate() {
    //fetch for related projects
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
          limit: 1,
          sort: { _created: -1 },
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.project = res.entries[0]))
      .catch((error) => console.log(error.message));
  },
  methods: {
    numberWithCommas: function(x) {
      return (x || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>