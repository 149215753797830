var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Header"},[_c('v-app-bar',{staticClass:"header-app",attrs:{"app":"","light":"","elevation":"0","fixed":"","color":_vm.colorChange,"height":_vm.heightAppBar},on:{"scroll":_vm.changeColorAppBar}},[_c('div',{staticClass:"d-flex align-center"},[(!_vm.changeLogoAppBar || _vm.submenu || _vm.menu_mobile)?[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"shrink mr-2 header-app--ofis-logo",attrs:{"alt":"OfisDesign logo","contain":"","src":require("@/assets/icons/ofis-logo-black.svg"),"transition":"fade-transition"}})],1)]:[_c('div',{staticClass:"header-app--logo-home",class:{
            'color-white':
              _vm.headerUpdate == 0 || _vm.changeColorItems == false || !_vm.submenu,
            'color-black': _vm.changeColorItems || _vm.headerUpdate == 1 || _vm.submenu,
          }},[_c('span',{staticClass:"d-block titles aeonik-regular"},[_vm._v("Architecture")]),_c('span',{staticClass:"d-block titles aeonik-regular"},[_vm._v("& Interior")]),_c('span',{staticClass:"d-block titles aeonik-regular"},[_vm._v("Design")])])]],2),_c('v-spacer'),_c('div',{staticClass:"d-none d-md-block"},[_vm._l((_vm.itemsHeader),function(itemH,ith){return [_c('router-link',{key:ith,staticClass:"not-text-decoration header-app--items-links",class:{
            'color-white':
              _vm.headerUpdate == 0 || _vm.changeColorItems == false || !_vm.submenu,
            'color-black': _vm.changeColorItems || _vm.headerUpdate == 1 || _vm.submenu,
          },attrs:{"to":itemH.url}},[_c('span',{staticClass:"texts capitalize aeonik-regular",on:{"mouseover":function($event){return _vm.openMenu(ith)}}},[_vm._v(_vm._s(itemH.text))])])]})],2),_c('div',{staticClass:"d-md-none"},[_c('v-img',{attrs:{"src":require("@/assets/icons/menu-mobile.svg")},on:{"click":function($event){_vm.menu_mobile= !_vm.menu_mobile}}})],1)],1),_c('MenuMobile',{attrs:{"show":_vm.menu_mobile,"itemsNav":_vm.itemsHeader}}),_c('div',{on:{"mouseleave":function($event){_vm.submenu = false}}},[_c('SubMenu',{attrs:{"show":_vm.submenu,"changeContent":_vm.changeContent}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }