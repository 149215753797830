<template>
  <div class="ta-dialog" id="ta-dialog">
    <v-dialog v-model="show" persistent attach="#ta-dialog" transition="fade-transition">
      <div
        @click.stop="show = false"
        class="text-right pt-3 pr-3"
        style="position: sticky; top: 1px; right: 0px; width; z-index: 1; background-color: white;"
      >
        <v-icon class="cursor-pointer close-icon black--text">mdi-close</v-icon>
      </div>

      <v-card elevation="0" tile color="white">
        <v-row no-gutters justify="center">
          <v-col cols="11">
            <!-- si changeContent == 0 - Términos y condiciones -->
            <v-row no-gutters v-if="changeContent == 0">
              <v-col class="text-center" cols="12">
                <span class="title-modal black--text"
                  >Términos y Condiciones</span
                >
              </v-col>

              <v-col class="mt-5 mt-sm-10 mx-3 mx-sm-12">
                <span class="text-modal black--text"
                  >Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Pariatur, libero vel? A totam asperiores magni id ut, libero
                  doloribus laudantium dignissimos dolore incidunt delectus
                  possimus. Aliquid ducimus dicta necessitatibus excepturi ut ad
                  aspernatur, id possimus nulla ullam. Illum ex nam cum tempora,
                  error, nihil laudantium ipsa ratione perspiciatis ad, minima
                  necessitatibus? Amet dolore possimus iste, corporis a
                  explicabo odio alias vero soluta quidem mollitia excepturi
                  doloribus iusto earum laboriosam quod saepe consequuntur et
                  eveniet. Tempore, perspiciatis repellendus. Tenetur
                  exercitationem incidunt quam pariatur cumque commodi facilis
                  enim, ea totam ullam iste! Provident hic eaque nam facere? Et
                  itaque nesciunt sunt tempora!</span
                >
              </v-col>
            </v-row>
            <!-- /si changeContent == 0 - Términos y condiciones -->

            <!-- si changeContent == 1 - Aviso de privacidad -->
            <v-row no-gutters v-if="changeContent == 1">
              <v-col class="text-center" cols="12">
                <span class="title-modal black--text">Aviso de privacidad</span>
              </v-col>

              <v-col class="mt-5 mt-sm-10 mx-3 mx-sm-12">
                <span class="text-modal black--text"
                  >Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Pariatur, libero vel? A totam asperiores magni id ut, libero
                  doloribus laudantium dignissimos dolore incidunt delectus
                  possimus. Aliquid ducimus dicta necessitatibus excepturi ut ad
                  aspernatur, id possimus nulla ullam. Illum ex nam cum tempora,
                  error, nihil laudantium ipsa ratione perspiciatis ad, minima
                  necessitatibus? Amet dolore possimus iste, corporis a
                  explicabo odio alias vero soluta quidem mollitia excepturi
                  doloribus iusto earum laboriosam quod saepe consequuntur et
                  eveniet. Tempore, perspiciatis repellendus. Tenetur
                  exercitationem incidunt quam pariatur cumque commodi facilis
                  enim, ea totam ullam iste! Provident hic eaque nam facere? Et
                  itaque nesciunt sunt tempora!</span
                >
              </v-col>
            </v-row>
            <!-- /si changeContent == 1 - Aviso de privacidad -->
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'TAModal',
  props: {
    visible: { type: Boolean, required: true },
    changeContent: { type: Number, required: true }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) return this.$emit('close')
      }
    }
  }
}
</script>
